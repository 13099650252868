<template>
  <div class="NavigationCopy_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item v-for="item in SiteList" :key="item.site_id" :index="item.site_id" :value="item.site_id">{{item.name}}</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：各个站点导航图标</div>
    </div>


    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="Refresh()"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelect"> 
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column label="图片"  align="center">
          <template #default="scope">
            <el-image class="Toper"
              :src="scope.row.full_image" alt=""
              :preview-src-list="[scope.row.full_image]"
              :initial-index="0"
              :hide-on-click-modal = true
              fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称"  align="center"></el-table-column>
        <el-table-column prop="link_type" label="跳转类型" align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重" align="center"></el-table-column>
        <el-table-column prop="createtime" label="添加时间" align="center"></el-table-column>
        <el-table-column prop="updatetime" label="更新时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.site_id" placeholder="请选择类型" >
                <el-option v-for="item  in SiteList" :key="item.site_id" :value="item.site_id" :label="item.name" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.link_type" placeholder="请选择类型" >
                <el-option v-for="item  in linkType" :key="item.id" :value="item" :label="item" ></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>导航名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写导航名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" v-if="Row.link_type == linkType.miniprogram">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>小程序 appId:</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.appid" placeholder="请填写"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" v-if="Row.link_type != linkType.none ">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>小程序链接</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.link_url" placeholder="请填写链接"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图片</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-image"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    

  </div>
</template>

<script>
import axios from 'axios'
import LoginVue from '../Login.vue'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      linkType:[],//统计列表
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      // 列表数据
      Row: {
        name: "",
        image: "",
        link_type: "",
        link_url: "",
        appid: "",
        weigh: "",
        site_id:''
      },
      //搜索参数
      search: {
        keyword: "",
        site_id: "",
      },
      site_id: 1,
      fileList:[],
      addPanel: false,
      editPanel: false,
      editId: 0,
      titleMath:'',
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    axios.get('/admin/ajax/siteList', {   // 站点列表
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.SiteList = res.data.data
        that.activeIndex = that.SiteList[0].site_id
      }
    })
  },
  methods: {
    // 导航选择
    handleSelect_top (e) {
      var that = this
      var site_id =  e
      axios({
        method: 'post',
        url: '/admin/general/homenav/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter:{
          site_id
         }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.search.site_id = e
      })
    },
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/homenav/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    //刷新
    Refresh(){
      this.page.currentPage = 1;
      this.search = ''
      this.loadTable();
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search = {
          keyword: "",
          site_id: "",
        }
      }
      that.loadTable();
    },
    // 跳转类型
    BtnType(){
      var that = this
      axios({
        method: 'GET',
        url: '/admin/general/homenav/statisticsColumns',
      }).then(function (res) {
        that.linkType = res.data.data.link_type
      })
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/homenav/add',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加'
      that.Row = {
        name: "",
        image: "",
        link_type_text: "",
        link_url: "",
        appid: "",
        weigh: 0,
        site_id:''
      };
      that.Row.link_type = ''
      that.fileList = [];
      that.BtnType()
    },
    // 编辑
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/general/homenav/edit',
          data: {
            id: that.editId,
            row:{
              name: that.Row.name,
              image: that.Row.image,
              link_type: that.Row.link_type,
              link_url: that.Row.link_url,
              appid: that.Row.appid,
              weigh: that.Row.weigh,
              site_id: that.Row.site_id,
            }
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    //获取编辑内容
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.id
      axios.get('/admin/general/homenav/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = {
            name: res.data.data.row.name,
            image: res.data.data.row.image,
            link_type: res.data.data.row.link_type,
            link_url: res.data.data.row.link_url,
            appid: res.data.data.row.appid,
            weigh: res.data.data.row.weigh,
            site_id: res.data.data.row.site_id,
          }
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_image;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
      that.BtnType()
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false;
    },
    //删除
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    delTable(arr) {
      var that = this
      axios({
        method: 'DELETE',
        url: '/admin/general/homenav/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.image = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-image"].clearFiles()
      this.$refs["upload-image"].handleStart(files[0])
      this.$refs["upload-image"].submit();
    },
    handlePreview(file) {
      console.log(file);
      window.open(file.url);
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>
